import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { MatBtnCellRendererComponent } from '../../../agGrid/mat-btn-cell-renderer/mat-btn-cell-renderer.component';
import { MatSideToggleComponent } from '../../../agGrid/mat-side-toggle/mat-side-toggle.component';
import { ManageQueryDialogComponent } from '../../../manage-query-dialog/manage-query-dialog.component';
import { QueryViewModel } from '../../../model/query';
import { QueryService } from '../../../services/query.service';
import { ReportService } from '../../../services/report.service';
import { SpinnerService } from '../../../services/spinner.service';

@Component({
    selector: 'app-sidebar-query-view',
    templateUrl: './query.component.html',
    styleUrls: ['./query.component.css'],
    standalone: false
})
export class QueryComponent implements OnInit {

  @Input() showSideBarManageQueriesView;
  @Input() rowData: any[] | null;
  @Input() selectedRowIndex;
  @Input() userName;
  @Input() reportType;
  @Output() onCloseSideBarManageQueriesView = new EventEmitter();
  
  columnDefs;
  gridApi;
  gridColumnApi;
  isReadOnly = false;
  hasGridData = false;
  result: any = {};
  queries: any = {};
  alertType = 'success';
  message = '';
  newSavedQueryId = 0;
  query = {
    condition: 'and',
    rules: [
    ]
  };
  constructor(public reportService: ReportService, public queryService: QueryService,
    private spinnerService: SpinnerService, public dialog: MatDialog, private messageService: MessageService) { }

  ngOnInit(): void {
    this.hasGridData = false;
    this.setQueryGrid();
   
  }

  ngOnChanges(changes: SimpleChanges) {
    this.hasGridData = false;
   
    if (this.showSideBarManageQueriesView)
      this.loadData();
  }
  onHide() {
    this.showSideBarManageQueriesView = false;
    this.onCloseSideBarManageQueriesView.emit(this.showSideBarManageQueriesView);
  }
  setQueryGrid() {
    this.columnDefs = [
      {
        field: 'id', headerName: ' ', width: 55,
        pinned: true, sortable: false, filter: false,
        cellRenderer: MatBtnCellRendererComponent,
        cellRendererParams: {
          clicked: this.onQueryGridEdit.bind(this),
          label: 'UPDATE',
          tooltip: 'Update',
          userName: this.userName
        },
        cellStyle: { 'overflow': 'unset', 'width': 'auto' }
      },
      {
        field: 'id', headerName: ' ', width: 55,
        pinned: true, sortable: false, filter: false,
        cellRenderer: MatBtnCellRendererComponent,
        cellRendererParams: {
          clicked: this.onQueryGridSaveAs.bind(this),
          label: 'SAVE_AS',
          tooltip: 'Save as'
        },
        cellStyle: { 'overflow': 'unset', 'width': 'auto' }
      },
      {
        field: 'id', headerName: ' ', width: 55,
        pinned: true, sortable: false, filter: false,
        cellRenderer: MatBtnCellRendererComponent,
        cellRendererParams: {
          clicked: this.onQueryGridRename.bind(this),
          label: 'RENAME1',
          tooltip: 'Rename',
          userName: this.userName
        },
        cellStyle: { 'overflow': 'unset', 'width': 'auto' }
      },
      {
        field: 'id', headerName: ' ', width: 55,
        pinned: true, sortable: false, filter: false,
        cellRenderer: MatBtnCellRendererComponent,
        cellRendererParams: {
          clicked: this.onQueryGridDelete.bind(this),
          label: 'DELETE',
          tooltip: 'Delete',
          userName: this.userName
        },
        cellStyle: { 'overflow': 'unset', 'width': 'auto' }
      },
      {
        field: 'ispublic', headerName: 'Is Public', pinned: true, width: 110, sortable: true, filter: true,
       
        cellRenderer: MatSideToggleComponent,
        cellRendererParams: {
          clicked: this.onUpdateQueryStatusAsPublic.bind(this),
          userName: this.userName
        }
      },
     
      { field: 'name', sort: 'asc', pinned: true, sortable: true, filter: true, width: 220 },
      { field: 'description', sortable: true, filter: true },
     
    ];
    if (this.showSideBarManageQueriesView)
    this.loadData();
    
  }
  loadData() {
    
    this.hasGridData = false;
    this.rowData = null;
    this.queries = {};
   
    this.spinnerService.show();
    this.queryService.getAll(this.userName, this.reportType)
      .subscribe({
        next: (result) => {
          this.spinnerService.hide();
          let _result: any = {};
          _result = result;

          if (_result.success) {
            this.queries = _result.result;
            this.rowData = _result.result;
            this.hasGridData = _result.total > 0;
            //if (this.hasGridData)
            //  this.showStatusMessage('No data found', 'Get Queries', 'success');
            //if (isCalledAfterModalClose && this.currentSavedQueryId > 0)
            //  this.onSavedQueryChange(this.currentSavedQueryId);

          }
          else {

            this.alertType = 'danger';
            let _errorInfo = _result && _result.errormessage ? _result.errormessage : '';
            this.message = 'Error while loading data. ' + _errorInfo;
            this.showStatusMessage(this.message, 'Get Queries', 'error');
          }
        },

        error: (err: any) => {
          let _err: any = {};
          _err = err;
          this.alertType = 'danger';
          let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';
          this.message = 'Error while loading data. ' + _err.errorMessage;
          this.showStatusMessage(this.message, 'Get Queries', 'error');
          this.spinnerService.hide();
        },
        complete: () => { }
      }
      );
      
  }
  showStatusMessage(message: any, action: any, severity: any = '') {
    severity == '' ? 'success' : 'error';

    this.messageService.add({ severity: severity, summary: action, detail: message });
  }
 
  queryUpdateIsPublic(query: any) {
    
    if (query.ispublic == 'On' || query.ispublic == 'Off')
      query.ispublic = !query.ispublic;
   else
      query.ispublic = !query.ispublic;
 
    query.type = this.reportType;
    query.createdDate = null;

    this.spinnerService.show();
    this.queryService.update(query)
      .subscribe({
        next: (result) => {
          this.spinnerService.hide();
          let _result: any = {};
          _result = result;

          if (_result.success) {
            //this.openSnackBar('Query Updated successfully.', 'DISMISS');
            this.loadData();
          }
          else {

            this.alertType = 'danger';
            let _errorInfo = _result && _result.errormessage ? _result.errormessage : '';
            this.message = 'Unable to update query, Please try it again! ' + _errorInfo;
            //this.openSnackBar(this.message, 'DISMISS');

          }
        },

        error: (err: any) => {
          this.spinnerService.hide();
          let _err: any = {};
          _err = err;
          this.alertType = 'danger';

          let _errorInfo = '';

          this.message = 'Unable to update query, Please try it again! ' + _errorInfo;
        },
        complete: () => { }
      }
      );
     
  }

  onOpenManageQueryDialog(id: number = 0, action: string = '') {
    this.newSavedQueryId = 0;
    const query: QueryViewModel = ({} as any) as QueryViewModel;
    
    query.id = id;
    if (id > 0) {
      Object.entries(this.queries).forEach(([key, val]) => {
        if (val['id'] == id) {
         
          query.uiQuery = JSON.parse(val['uiquery']);
          query.name = val['name'];
          query.description = val['description'];
          query.isPublic = val['ispublic'];
          query.sqlQuery = val['sqlquery'];
          query.oracleQuery = val['oraclequery'];
          query.createdBy = val['createdby'];
          query['uiquery'] = JSON.parse(val['uiquery']);
          return;
        }
      });
    }
    let _position = this.showSideBarManageQueriesView ? '30%' : '';

    const dialogRef = this.dialog.open(ManageQueryDialogComponent,
      {
        width: '400px',
        disableClose: true, position: { right: _position },
        data: { query: query, reportType: this.reportType, userName: this.userName, action: action, uiQuery: query.uiQuery }
      });


    dialogRef.afterClosed().subscribe(result => {
      this.loadData();
    });

  }
  onUpdateQueryStatusAsPublic(params: any) {
    if (params.data.id > 0)
      this.queryUpdateIsPublic(params.data);
  }

  onQueryGridEdit(params: any) {
    if (params.data.id > 0)
      this.onOpenManageQueryDialog(params.data.id, 'UPDATE');
  }

  onQueryGridSaveAs(params: any) {
    if (params.data.id > 0)
      this.onOpenManageQueryDialog(params.data.id, 'SAVE_AS');
  }
  onQueryGridRename(params: any) {
    if (params.data.id > 0)
      this.onOpenManageQueryDialog(params.data.id, 'RENAME');
  }
  onQueryGridDelete(params: any) {
    if (params.data.id > 0)
      this.onOpenManageQueryDialog(params.data.id, 'DELETE');
  }
}
