
<ng-template [ngIf]="showSideBarSubReportView">
  <p-sidebar [ngStyle]="{'display':(showSideBarSubReportView) ? 'block' : 'none' }" class="no-newTabView" [ngClass]="showSideBarSubReportView" [(visible)]="showSideBarSubReportView" (onHide)="onHide()" [style]="getPageWidth()" [showCloseIcon]="false" position="right" [baseZIndex]="10000">

    <ng-template pTemplate="header">
      <div class="container">
        <div class="row">
          <div class="col-6 h2">{{title}}</div>
          <div class="col text-center">
            <span *ngIf="type == 'AIRPORT_DIAGRAM' ">
              {{airportCode}} -
              {{airportName}}

              <button class="btn btn-outline-primary faa-btn-left-space-10px" *ngIf="incidentDate !== '' "
                      (click)="showAllDigram()"
                      matTooltip="Show All Diagrams">
                Show All Diagram
              </button>
            </span>
            <span *ngIf="type == 'NARRATIVE_INFORMATION' && selectedRow " style="font-weight:normal">
              Incident ID:<b> {{selectedRow['merged_data_source_id']}}</b>
            </span>
            <button class="btn btn-outline-primary faa-btn-left-space-10px" *ngIf="showDownload"
                    (click)="onDownload()"
                    matTooltip="Click to download current grid view data" [disabled]="!hasGridData">
              Download
            </button>
          </div>
          <div class="col-1 text-end">
            <button mat-mini-fab color="primary" (click)="onHide()" matTooltip="Close window" aria-label="Close">
              <mat-icon>close</mat-icon>
            </button>
          </div>

        </div>
      </div>

    </ng-template>
    <div class="card">

      <div class="card-body card-body-usda-table " *ngIf="type === 'NARRATIVE_INFORMATION' && rowData &&  !hasOtherDataSourceType ">


        <div class="card" style="margin-bottom:5px;">

          <div class="card-header">
            <div class="row">
              <div class="col-md-4">
                Airport Code: <span class="font-weight-bold" *ngIf="hasGridData && rowData[0]?.airport_code">{{rowData[0]?.airport_code}} </span>
              </div>
              <div class="col-md-4">
                Data Source: <span class="font-weight-bold" *ngIf="hasGridData && rowData[0]?.data_source">{{rowData[0]?.data_source}} </span>
              </div>
              <div class="col-md-4 text-end">
                <button mat-raised-button (click)="accordion.openAll()">Expand All</button>&nbsp;&nbsp;
                <button mat-raised-button (click)="accordion.closeAll()">Collapse All</button>
              </div>
            </div>
          </div>
        </div>

        <div class="card-accordion">
          <mat-accordion multi>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  ASRS
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.asrs_rpt1_narrative)">
                <div class="card-header primary-header col-md-12 font-weight-bold">ASRS RPT1 NARRATIVE:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.asrs_rpt1_narrative"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.asrs_rpt2_narrative)">
                <div class="card-header primary-header col-md-12 font-weight-bold">ASRS RPT2 NARRATIVE:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.asrs_rpt2_narrative"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.asrs_result)">
                <div class="card-header primary-header col-md-12 font-weight-bold">ASRS RESULT:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.asrs_result"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.asrs_rpt1_callback)">
                <div class="card-header primary-header col-md-12 font-weight-bold">ASRS RPT1 CALLBACK:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.asrs_rpt1_callback"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.asrs_rpt2_callback)">
                <div class="card-header primary-header col-md-12 font-weight-bold">ASRS RPT2 CALLBACK:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.asrs_rpt2_callback"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.asrs_rpt1_synopsis)">
                <div class="card-header primary-header col-md-12 font-weight-bold">ASRS RPT1 SYNOPSIS:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.asrs_rpt1_synopsis"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.asrs_problem)">
                <div class="card-header primary-header col-md-12 font-weight-bold">ASRS PROBLEM:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.asrs_problem"></span>
                </div>
              </div>

              <br />

            </mat-expansion-panel>


            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  NTSB
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
              </mat-expansion-panel-header>


              <div class="card" *ngIf="showValueWithData(rowData[0]?.ntsb_rprt_narr_prelim_text)">
                <div class="card-header primary-header font-weight-bold">NTSB RPRT NARR PRELIM TEXT:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.ntsb_rprt_narr_prelim_text"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.ntsb_rprt_narr_cause_text)">
                <div class="card-header primary-header col-md-12 font-weight-bold">NTSB RPRT NARR CAUSE TEXT:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.ntsb_rprt_narr_cause_text"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.ntsb_rprt_narr_faa_text)">
                <div class="card-header primary-header col-md-12 font-weight-bold">NTSB RPRT NARR FAA TEXT:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.ntsb_rprt_narr_faa_text"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.ntsb_rprt_narr_final_text)">
                <div class="card-header primary-header col-md-12 font-weight-bold">NTSB RPRT NARR FINAL TEXT:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.ntsb_rprt_narr_final_text"></span>
                </div>
              </div>
              <br />

            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  RWS
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.rws_rwy_sfty_narrative)">
                <div class="card-header primary-header col-md-12 font-weight-bold">RWS RWY SFTY NARRATIVE:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.rws_rwy_sfty_narrative"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.rws_rwy_sfty_asmnt_rmk)">
                <div class="card-header primary-header col-md-12 font-weight-bold">RWS RWY SFTY ASMNT RMK:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.rws_rwy_sfty_asmnt_rmk"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.rws_rwy_sfty_narrative)">
                <div class="card-header primary-header col-md-12 font-weight-bold">RWS ORIGINAL NARRATIVE:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.rws_original_narrative"></span>
                </div>
              </div>

              <br />


            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  PDS
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
              </mat-expansion-panel-header>


              <div class="card" *ngIf="showValueWithData(rowData[0]?.pds_corr_rmk_text)">
                <div class="card-header primary-header col-md-12 font-weight-bold">PDS CORR RMK TEXT:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.pds_corr_rmk_text"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.pds_prelim_rmk_text)">
                <div class="card-header primary-header col-md-12 font-weight-bold">PDS PRELIM RMK TEXT:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.pds_prelim_rmk_text"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.pds_inves_rmk_text)">
                <div class="card-header primary-header col-md-12 font-weight-bold">PDS INVES RMK TEXT:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.pds_inves_rmk_text"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.pds_entry_rmk_text)">
                <div class="card-header primary-header col-md-12 font-weight-bold">PDS ENTRY RMK TEXT:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.pds_entry_rmk_text"></span>
                </div>
              </div>

              <br />
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  VPDS
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.vpd_rmk_text_entry)">
                <div class="card-header primary-header col-md-12 font-weight-bold">VPDS RMK TEXT ENTRY:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.vpd_rmk_text_entry"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.vpd_rmk_text_inves)">
                <div class="card-header primary-header col-md-12 font-weight-bold">VPDS RMK TEXT INVES:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.vpd_rmk_text_inves "></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.vpd_rmk_text_narrat)">
                <div class="card-header primary-header col-md-12 font-weight-bold">VPDS RMK TEXT NARRAT:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.vpd_rmk_text_narrat"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.vpd_rmk_text_prelim)">
                <div class="card-header primary-header col-md-12 font-weight-bold">VPDS RMK TEXT PRELIM:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.vpd_rmk_text_prelim "></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.vpd_rmk_text_corr)">
                <div class="card-header primary-header col-md-12 font-weight-bold">VPDS RMK TEXT CORR:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.vpd_rmk_text_corr"></span>
                </div>
              </div>
              <br />
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  AIDS
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="card" *ngIf="showValueWithData(rowData[0]?.aid_rmk_text)">
                <div class="card-header primary-header font-weight-bold">AIDS Remarks Text:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.aid_rmk_text"></span>
                </div>
              </div>
              <br />
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Analyst Notes
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.notes)">
                <div class="card-body"> <span [innerHTML]="rowData[0]?.notes "></span></div>
              </div>
              <br />

            </mat-expansion-panel>
          </mat-accordion>

        </div>


      </div>

      <div class="card-body card-body-usda-table" *ngIf="hasOtherDataSourceType && hasGridData">

        <div class="table-responsive1 " [hidden]="!hasGridData">

          <div class="form detail-form">

            <form>
              <div class="form-row row" *ngIf="reportColumns?.length > 0 ">

                <ng-template ngFor let-item [ngForOf]="reportColumns">

                  <mat-form-field [ngClass]="getBsClass(item.db_column_name)" *ngIf="item.display_name?.length > 0 && rowData">
                    <mat-label style="font-weight: bold">{{item.display_name}}:</mat-label>
                    <ng-template [ngIf]="item.control == 'TEXTAREA'">
                      <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" matInput value="{{rowData[0][item.db_column_name]}}" [readonly]="isReadOnly"></textarea>
                    </ng-template>
                    <ng-template [ngIf]="item.control !== 'TEXTAREA'">
                      <input matInput value="{{rowData[0][item.db_column_name]}}" [readonly]="isReadOnly">
                    </ng-template>

                  </mat-form-field>

                </ng-template>

              </div>

            </form>
          </div>

        </div>

      </div>

      <div class="card-body card-body-usda-table" [hidden]="type == 'NARRATIVE_INFORMATION'  ">

        <div class="table-responsive " [hidden]="!hasGridData || ( type == 'NARRATIVE_INFORMATION' || hasOtherDataSourceType ) ">

          <div class="card-body1 hostsport-table table-responsive" *ngIf="hotSpots && hotSpots">
            <table class="table table-sm table-hover table-bordered">
              <thead *ngIf="hasHotSpots">
                <tr>
                  <th scope="col">Hot Spot Name</th>
                  <th scope="col">Start Date Range</th>
                  <th scope="col">End Date Range</th>
                  <th scope="col">Hot Spot Description</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of hotSpots">

                  <th scope="row">{{item?.hot_spot_name}}</th>
                  <td>{{item?.start_date_range}}</td>
                  <td>{{item?.end_date_range}}</td>
                  <td>{{item?.hot_spot_description}}</td>
                </tr>

              </tbody>
            </table>
          </div>
          <ag-grid-angular style="width: auto; height: 530px; "
                           class="ag-theme-alpine faa-airport-diagram-grid"
                           [rowData]="rowData"
                           [columnDefs]="columnDefs"
                           (gridReady)="onGridReady($event)"
                           [paginationPageSize]="10"
                           [pagination]="true">
          </ag-grid-angular>
        </div>


      </div>
      <div class="" [hidden]="hasGridData">
        No data found.
      </div>
    </div>

  </p-sidebar>
</ng-template>




<ng-template [ngIf]="showSideBarSubReportView">
  <p-sidebar [ngStyle]="{'display':(showSideBarSubReportView) ? 'block' : 'none' }" class="no-newTabView" [ngClass]="showSideBarSubReportView" [(visible)]="showSideBarSubReportView" (onHide)="onHide()" [style]="getPageWidth()" [showCloseIcon]="false" position="right" [baseZIndex]="10000">

    <ng-template pTemplate="header">
      <div class="container">
        <div class="row">
          <div class="col-6 h2">{{title}}</div>
          <div class="col text-center">
            <span *ngIf="type == 'AIRPORT_DIAGRAM' ">
              {{airportCode}} -
              {{airportName}}

              <button class="btn btn-outline-primary faa-btn-left-space-10px" *ngIf="incidentDate !== '' "
                      (click)="showAllDigram()"
                      matTooltip="Show All Diagrams">
                Show All Diagram
              </button>
            </span>
            <span *ngIf="type == 'NARRATIVE_INFORMATION' && selectedRow " style="font-weight:normal">
              Incident ID:<b> {{selectedRow['merged_data_source_id']}}</b>
            </span>
            <button class="btn btn-outline-primary faa-btn-left-space-10px" *ngIf="showDownload"
                    (click)="onDownload()"
                    matTooltip="Click to download current grid view data" [disabled]="!hasGridData">
              Download
            </button>
          </div>
          <div class="col-1 text-end">
            <button mat-mini-fab color="primary" (click)="onHide()" matTooltip="Close window" aria-label="Close">
              <mat-icon>close</mat-icon>
            </button>
          </div>

        </div>
      </div>

    </ng-template>
    <div class="card">

      <div class="card-body card-body-usda-table " *ngIf="type === 'NARRATIVE_INFORMATION' && rowData &&  !hasOtherDataSourceType ">


        <div class="card" style="margin-bottom:5px;">

          <div class="card-header">
            <div class="row">
              <div class="col-md-4">
                Airport Code: <span class="font-weight-bold" *ngIf="hasGridData && rowData[0]?.airport_code">{{rowData[0]?.airport_code}} </span>
              </div>
              <div class="col-md-4">
                Data Source: <span class="font-weight-bold" *ngIf="hasGridData && rowData[0]?.data_source">{{rowData[0]?.data_source}} </span>
              </div>
              <div class="col-md-4 text-end">
                <button mat-raised-button (click)="accordion.openAll()">Expand All</button>&nbsp;&nbsp;
                <button mat-raised-button (click)="accordion.closeAll()">Collapse All</button>
              </div>
            </div>
          </div>
        </div>

        <div class="card-accordion">
          <mat-accordion multi>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  ASRS
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.asrs_rpt1_narrative)">
                <div class="card-header primary-header col-md-12 font-weight-bold">ASRS RPT1 NARRATIVE:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.asrs_rpt1_narrative"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.asrs_rpt2_narrative)">
                <div class="card-header primary-header col-md-12 font-weight-bold">ASRS RPT2 NARRATIVE:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.asrs_rpt2_narrative"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.asrs_result)">
                <div class="card-header primary-header col-md-12 font-weight-bold">ASRS RESULT:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.asrs_result"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.asrs_rpt1_callback)">
                <div class="card-header primary-header col-md-12 font-weight-bold">ASRS RPT1 CALLBACK:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.asrs_rpt1_callback"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.asrs_rpt2_callback)">
                <div class="card-header primary-header col-md-12 font-weight-bold">ASRS RPT2 CALLBACK:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.asrs_rpt2_callback"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.asrs_rpt1_synopsis)">
                <div class="card-header primary-header col-md-12 font-weight-bold">ASRS RPT1 SYNOPSIS:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.asrs_rpt1_synopsis"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.asrs_problem)">
                <div class="card-header primary-header col-md-12 font-weight-bold">ASRS PROBLEM:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.asrs_problem"></span>
                </div>
              </div>

              <br />

            </mat-expansion-panel>


            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  NTSB
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
              </mat-expansion-panel-header>


              <div class="card" *ngIf="showValueWithData(rowData[0]?.ntsb_rprt_narr_prelim_text)">
                <div class="card-header primary-header font-weight-bold">NTSB RPRT NARR PRELIM TEXT:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.ntsb_rprt_narr_prelim_text"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.ntsb_rprt_narr_cause_text)">
                <div class="card-header primary-header col-md-12 font-weight-bold">NTSB RPRT NARR CAUSE TEXT:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.ntsb_rprt_narr_cause_text"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.ntsb_rprt_narr_faa_text)">
                <div class="card-header primary-header col-md-12 font-weight-bold">NTSB RPRT NARR FAA TEXT:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.ntsb_rprt_narr_faa_text"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.ntsb_rprt_narr_final_text)">
                <div class="card-header primary-header col-md-12 font-weight-bold">NTSB RPRT NARR FINAL TEXT:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.ntsb_rprt_narr_final_text"></span>
                </div>
              </div>
              <br />

            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  RWS
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.rws_rwy_sfty_narrative)">
                <div class="card-header primary-header col-md-12 font-weight-bold">RWS RWY SFTY NARRATIVE:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.rws_rwy_sfty_narrative"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.rws_rwy_sfty_asmnt_rmk)">
                <div class="card-header primary-header col-md-12 font-weight-bold">RWS RWY SFTY ASMNT RMK:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.rws_rwy_sfty_asmnt_rmk"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.rws_rwy_sfty_narrative)">
                <div class="card-header primary-header col-md-12 font-weight-bold">RWS ORIGINAL NARRATIVE:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.rws_original_narrative"></span>
                </div>
              </div>

              <br />


            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  PDS
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
              </mat-expansion-panel-header>


              <div class="card" *ngIf="showValueWithData(rowData[0]?.pds_corr_rmk_text)">
                <div class="card-header primary-header col-md-12 font-weight-bold">PDS CORR RMK TEXT:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.pds_corr_rmk_text"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.pds_prelim_rmk_text)">
                <div class="card-header primary-header col-md-12 font-weight-bold">PDS PRELIM RMK TEXT:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.pds_prelim_rmk_text"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.pds_inves_rmk_text)">
                <div class="card-header primary-header col-md-12 font-weight-bold">PDS INVES RMK TEXT:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.pds_inves_rmk_text"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.pds_entry_rmk_text)">
                <div class="card-header primary-header col-md-12 font-weight-bold">PDS ENTRY RMK TEXT:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.pds_entry_rmk_text"></span>
                </div>
              </div>

              <br />
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  VPDS
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.vpd_rmk_text_entry)">
                <div class="card-header primary-header col-md-12 font-weight-bold">VPDS RMK TEXT ENTRY:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.vpd_rmk_text_entry"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.vpd_rmk_text_inves)">
                <div class="card-header primary-header col-md-12 font-weight-bold">VPDS RMK TEXT INVES:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.vpd_rmk_text_inves "></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.vpd_rmk_text_narrat)">
                <div class="card-header primary-header col-md-12 font-weight-bold">VPDS RMK TEXT NARRAT:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.vpd_rmk_text_narrat"></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.vpd_rmk_text_prelim)">
                <div class="card-header primary-header col-md-12 font-weight-bold">VPDS RMK TEXT PRELIM:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.vpd_rmk_text_prelim "></span>
                </div>
              </div>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.vpd_rmk_text_corr)">
                <div class="card-header primary-header col-md-12 font-weight-bold">VPDS RMK TEXT CORR:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.vpd_rmk_text_corr"></span>
                </div>
              </div>
              <br />
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  AIDS
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="card" *ngIf="showValueWithData(rowData[0]?.aid_rmk_text)">
                <div class="card-header primary-header font-weight-bold">AIDS Remarks Text:</div>
                <div class="card-body">
                  <span [innerHTML]="rowData[0]?.aid_rmk_text"></span>
                </div>
              </div>
              <br />
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Analyst Notes
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="card" *ngIf="showValueWithData(rowData[0]?.notes)">
                <div class="card-body"> <span [innerHTML]="rowData[0]?.notes "></span></div>
              </div>
              <br />

            </mat-expansion-panel>
          </mat-accordion>

        </div>


      </div>

      <div class="card-body card-body-usda-table" *ngIf="hasOtherDataSourceType && hasGridData">

        <div class="table-responsive1 " [hidden]="!hasGridData">

          <div class="form detail-form">

            <form>
              <div class="form-row row" *ngIf="reportColumns?.length > 0 ">

                <ng-template ngFor let-item [ngForOf]="reportColumns">

                  <mat-form-field [ngClass]="getBsClass(item.db_column_name)" *ngIf="item.display_name?.length > 0 && rowData">
                    <mat-label style="font-weight: bold">{{item.display_name}}:</mat-label>
                    <ng-template [ngIf]="item.control == 'TEXTAREA'">
                      <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" matInput value="{{rowData[0][item.db_column_name]}}" [readonly]="isReadOnly"></textarea>
                    </ng-template>
                    <ng-template [ngIf]="item.control !== 'TEXTAREA'">
                      <input matInput value="{{rowData[0][item.db_column_name]}}" [readonly]="isReadOnly">
                    </ng-template>

                  </mat-form-field>

                </ng-template>

              </div>

            </form>
          </div>

        </div>

      </div>

      <div class="card-body card-body-usda-table" [hidden]="type == 'NARRATIVE_INFORMATION'  ">

        <div class="table-responsive " [hidden]="!hasGridData || ( type == 'NARRATIVE_INFORMATION' || hasOtherDataSourceType ) ">

          <div class="card-body1 hostsport-table table-responsive" *ngIf="hotSpots && hotSpots">
            <table class="table table-sm table-hover table-bordered">
              <thead *ngIf="hasHotSpots">
                <tr>
                  <th scope="col">Hot Spot Name</th>
                  <th scope="col">Start Date Range</th>
                  <th scope="col">End Date Range</th>
                  <th scope="col">Hot Spot Description</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of hotSpots">

                  <th scope="row">{{item?.hot_spot_name}}</th>
                  <td>{{item?.start_date_range}}</td>
                  <td>{{item?.end_date_range}}</td>
                  <td>{{item?.hot_spot_description}}</td>
                </tr>

              </tbody>
            </table>
          </div>
          <ag-grid-angular style="width: auto; height: 530px; "
                           class="ag-theme-alpine faa-airport-diagram-grid"
                           [rowData]="rowData"
                           [columnDefs]="columnDefs"
                           (gridReady)="onGridReady($event)"
                           [paginationPageSize]="10"
                           [pagination]="true">
          </ag-grid-angular>
        </div>


      </div>
      <div class="" [hidden]="hasGridData">
        No data found.
      </div>
    </div>

  </p-sidebar>
</ng-template>

<ng-template [ngIf]="hasNewTabView">
  <div class="newTabView" *ngIf="hasNewTabView">
    <div class="card">
      <div class="card-body">
        <div class="row font-weight-bold ">
          <span class="col-md-5 h1">{{title}}</span>
          <span class="col-md-7 text-end">
            <span *ngIf="type == 'AIRPORT_DIAGRAM' ">
              {{airportCode}} -
              {{airportName}}
              <button class="btn btn-outline-primary faa-btn-left-space-10px" *ngIf="incidentDate !== '' "
                      (click)="showAllDigram()"
                      matTooltip="Show All Diagrams">
                Show All Diagram
              </button>
            </span>

            <span *ngIf="type == 'NARRATIVE_INFORMATION' && selectedRow " style="font-weight:normal">
              Incident ID:<b> {{selectedRow['merged_data_source_id']}}</b>
            </span>
            <button class="btn btn-outline-primary faa-btn-left-space-10px" *ngIf="showDownload"
                    (click)="onDownload()"
                    matTooltip="Click to download current grid view data" [disabled]="!hasGridData">
              Download
            </button>

          </span>
        </div>

        <hr />

        <div class="card-body card-body-usda-table" [hidden]="type == 'NARRATIVE_INFORMATION'  ">

          <div class="table-responsive " [hidden]="!hasGridData || ( type == 'NARRATIVE_INFORMATION' || hasOtherDataSourceType ) ">

            <div class="card-body1 hostsport-table table-responsive" *ngIf="hotSpots && hotSpots">
              <table class="table table-sm table-hover table-bordered">
                <thead *ngIf="hasHotSpots">
                  <tr>
                    <th scope="col">Hot Spot Name</th>
                    <th scope="col">Start Date Range</th>
                    <th scope="col">End Date Range</th>
                    <th scope="col">Hot Spot Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of hotSpots">

                    <th scope="row">{{item?.hot_spot_name}}</th>
                    <td>{{item?.start_date_range}}</td>
                    <td>{{item?.end_date_range}}</td>
                    <td>{{item?.hot_spot_description}}</td>
                  </tr>

                </tbody>
              </table>
            </div>
            <ag-grid-angular style="width: auto; height: 530px; "
                             class="ag-theme-alpine faa-airport-diagram-grid"
                             [rowData]="rowData"
                             [columnDefs]="columnDefs"
                             (gridReady)="onGridReady($event)"
                             [paginationPageSize]="10"
                             [pagination]="true">
            </ag-grid-angular>
          </div>


        </div>
        <div class="card-body card-body-usda-table" *ngIf="hasOtherDataSourceType && hasGridData">

          <div class="table-responsive1 " [hidden]="!hasGridData">

            <div class="form detail-form">

              <form>
                <div class="form-row row" *ngIf="reportColumns?.length > 0 ">

                  <ng-template ngFor let-item [ngForOf]="reportColumns">

                    <mat-form-field [ngClass]="getBsClass(item.db_column_name)" *ngIf="item.display_name?.length > 0 && rowData">
                      <mat-label style="font-weight: bold">{{item.display_name}}:</mat-label>
                      <ng-template [ngIf]="item.control == 'TEXTAREA'">
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" matInput value="{{rowData[0][item.db_column_name]}}" [readonly]="isReadOnly"></textarea>
                      </ng-template>
                      <ng-template [ngIf]="item.control !== 'TEXTAREA'">
                        <input matInput value="{{rowData[0][item.db_column_name]}}" [readonly]="isReadOnly">
                      </ng-template>

                    </mat-form-field>

                  </ng-template>

                </div>

              </form>
            </div>

          </div>

        </div>


        <div class="" [hidden]="hasGridData">
          No data found.
        </div>
      </div>
    </div>
  </div>
</ng-template>
