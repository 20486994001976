import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-narrative-information',
    templateUrl: './narrative-information.component.html',
    styleUrls: ['./narrative-information.component.css'],
    standalone: false
})
export class NarrativeInformationComponent implements OnInit {
  @Input() selectedRow;
  rowData : any[] | null;
  @Input() showNarrativeView: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {
    this.onInit();
  }
  onInit() {
    if (this.showNarrativeView) {
      if (this.selectedRow) {
        this.rowData = this.selectedRow;
      }
    }
  }
  showValueWithData(data: any) {

    return data && data != '' && data.toString().length > 0;
  }

}
