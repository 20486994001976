import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-bi-report',
    templateUrl: './bi-report.component.html',
    styleUrl: './bi-report.component.css',
    standalone: false
})
export class BiReportComponent implements OnInit {

  appData: Subscription;
  biReportLink: any;
  currentTime = new Date().getTime();
  constructor(private http: HttpClient) { }
  ngOnInit() {

    this.appData = this.http.get('../../assets/appData.json?v=' + this.currentTime).subscribe({
      next: (res) => {
        let _result: any = {};
        _result = res;
        this.biReportLink = _result.biReportLink;
        //  this.biReportLink = this.sanitizer.bypassSecurityTrustUrl(_result.biReportLink);
        this.onOpenReportInNewWindow();
      },
      error: () => {
      }
    });

  }
  onOpenReportInNewWindow() {
    window.open(this.biReportLink, "ASD-BiReport");
  }
}
