import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizeService } from '../../api-authorization/authorize.service';
import { AuthorizedAccessDialog } from './authorizeAccess-dialog.component';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    standalone: false
})
export class HomeComponent {
  title = 'Airport Safety Database';
  airport_count = '3,100';
  homeAnnouncement = '';
  public isAuthenticated: Observable<boolean>;
  role = '';
  currentTime = new Date().getTime();
  constructor(

    private authorizeService: AuthorizeService,
    public router: Router,
    private http: HttpClient,
    public dialog: MatDialog) {

  }

  ngOnInit() {
    this.isAuthenticated = this.authorizeService.isAuthenticated();
    
    this.setAppDataValues();

    this.getUserData();

  }
  setAppDataValues() {
    this.http.get('../../assets/appData.json?v=' + this.currentTime)
      .subscribe({
        next: (result) => {
          let _result: any = {};
          _result = result;
          this.homeAnnouncement = _result.homeAnnouncement;
          this.airport_count = _result?.airport_count;
        },

        error: (err: any) => {
          let _err: any = {};
          _err = err;
        },
        complete: () => { //
        }
      }
      );
  }
  getUserData() {
    this.isAuthenticated = this.authorizeService.isAuthenticated();
    this.role = this.authorizeService.getUserRole();
  }
  onOpenAuthorizedAccessDialog(): void {
   
    this.dialog.open(AuthorizedAccessDialog, {
      width: '400px'
    });

  }
}
