import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Router } from '@angular/router';

import { AuthorizeService } from '../../../api-authorization/authorize.service';
import { SharedService } from '../../services/shared.service';
import { PasswordRuleType } from '../../model/shared';
import { MessageService } from 'primeng/api';
import { FAALocalStorageService } from '../../services/localStorage.service';
import { SpinnerService } from '../../services/spinner.service';

@Component({
    selector: 'user-dialog',
    templateUrl: 'user-dialog.html',
    standalone: false
})
export class UserDialog {
  title = 'Login';
  loginForm: UntypedFormGroup;
  message: string = '';
  hasForgotPassword = false;
  hasPassCode = false;
  alertType = 'success';
  type = 'LOGIN';
  user: any = {};
  roles: any = {};
  hasLoadedRoles = false;
  userName = '';
  orignalUserName = '';
  role = 'PUBLIC';
  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<UserDialog>, public authorizeService: AuthorizeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: HttpClient, private sharedService: SharedService,
    private localStorageService: FAALocalStorageService, private spinnerService: SpinnerService,
    private router: Router, private messageService: MessageService) { }
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  async ngOnInit() {
    this.role = 'PUBLIC';
    this.user = this.authorizeService.getUserData();
    this.loadRoles();
    this.initForm();

  }
  initForm() {
    this.userName = this.authorizeService.getUserName();
    this.orignalUserName = '';
    this.type = this.data.type;
    if (this.type !== 'PROFILE')
      this.user = this.data.user;

    this.message = '';
    if (this.type == 'PROFILE' || this.type == 'EDIT_USER') {

      if (this.type == 'EDIT_USER') {
        this.title = 'Edit User';
        this.loginForm = this.fb.group({
          email: ['', [Validators.required, emailValidator]],
          userName: '',
          firstName: ['', Validators.required],
          lastName: ['', Validators.required],
          phoneNumber: ['', phoneValidator],
          role: ''

        });
        this.loginForm.controls.userName.setValue(this.user.username);
        this.loginForm.controls.firstName.setValue(this.user.firstname);
        this.loginForm.controls.lastName.setValue(this.user.lastname);
        this.loginForm.controls.email.setValue(this.user.email);
        this.loginForm.controls.phoneNumber.setValue(this.user.phonenumber);
        if (this.user && this.user.role)
          this.role = this.user.role;

        this.setCurrentRole(this.role);
      }
      else {
        this.title = 'Update Profile';

        this.loginForm = this.fb.group({
          email: ['', [Validators.required, emailValidator]],
          userName: '',
          firstName: ['', Validators.required],
          lastName: ['', Validators.required],
          phoneNumber: ['', phoneValidator],
          role: '',
        });

        this.loginForm.controls.userName.setValue(this.user.username);
        this.loginForm.controls.firstName.setValue(this.user.firstname);
        this.loginForm.controls.lastName.setValue(this.user.lastname);
        this.loginForm.controls.email.setValue(this.user.email);
        this.loginForm.controls.phoneNumber.setValue(this.user.phonenumber);
        if (this.user && this.user.role)
          this.role = this.user.role;

        this.setCurrentRole(this.role);
      }


    }
    else if (this.type == 'NEW_USER' || this.type == 'EDIT_USER') {
      this.title = 'Create New User';

      this.loginForm = this.fb.group({
        email: ['', [Validators.required, emailValidator]],
        userName: '',
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        phoneNumber: ['', phoneValidator],
        password: ['', Validators.required],
        newPassword: ['', Validators.required],
        role: ''

      });
    }

    this.orignalUserName = this.loginForm.value.email;

  }
  onNoClick(): void {
    this.dialogRef.close();
  }


  onUpdateProfile() {

    this.message = '';
    const _type = this.type == 'PROFILE' ? 'Profile' : 'User';
    const _action = this.type == 'PROFILE' ? 'Update Profile' : 'Manage User';
    
    this.spinnerService.show();
    this.authorizeService.updateProfile(this.loginForm.value)
      .subscribe({
        next: (result) => {
          this.spinnerService.hide();
          let _result: any = {};
          _result = result;

          if (_result.success) {
            this.alertType = 'success';
            if (this.userName === this.orignalUserName && this.userName !== this.loginForm.value.email)
              this.onSuccessWithUserNameChange(_type, _action);
            else if (this.userName === this.orignalUserName && this.userName === this.loginForm.value.email)
              this.onSuccessWithCurrentUserWithoutUserNameChange(_type, _action, _result);
            else {
              this.message = _type + ' Updated Successfully.';
              this.showStatusMessage(this.message, _action, 'success');
              setTimeout(() => {
                this.dialogRef.close();
              }, 0);
            }
          }
          else {

            this.alertType = 'danger';
            const _errorInfo = _result && _result.errormessage ? _result.errormessage : '';
            this.message = 'Unable to update ' + _type + ', Please try it again! ' + _errorInfo;
            this.showStatusMessage(this.message, _action, 'error');
          }
        },

        error: (err: any) => {
          this.spinnerService.hide();
          let _err: any = {};
          _err = err;
          this.alertType = 'danger';
          console.log(_err);
          const _errorInfo = '';
          if (_err.status && _err.status == 404)
            this.dialogRef.close('LOGOUT');
          this.message = 'Unable to update ' + _type + ', Please try it again! ' + _errorInfo;
          this.showStatusMessage(this.message, _action, 'error');

        },
        complete: () => {
          //complete
        }
      }
      );

  }
  onSuccessWithCurrentUserWithoutUserNameChange(_type: any, _action: any, result: any) {
    this.message = _type + ' Updated Successfully.';;
    this.localStorageService.set('asd-user', result);
    this.showStatusMessage(this.message, _action, 'success');
    setTimeout(() => {
      this.dialogRef.close();
    }, 0);
  }
  onSuccessWithUserNameChange(_type: any, _action: any) {
    this.localStorageService.remove('asd-user');
    this.message = _type + ' Data Updated successfully. Use new email address as User name for next login.';
    this.showStatusMessage(this.message, _action, 'success');
    setTimeout(() => {
      this.dialogRef.close('LOGOUT');
    }, 5000);
  }
  onCreateUser() {

    this.message = '';
    this.spinnerService.show();

    this.authorizeService.createUser(this.loginForm.value)
      .subscribe({
        next: (result) => {
          this.spinnerService.hide();
          let _result: any = {};
          _result = result;

          if (_result.success) {
            this.alertType = 'success';

            this.message = 'User Created successfully.';
            this.showStatusMessage(this.message, 'Create User', 'success');
            setTimeout(() => {
              this.dialogRef.close();
            }, 10);
          }
          else {

            this.alertType = 'danger';
            const _errorInfo = _result && _result.errormessage ? _result.errormessage : '';
            this.message = 'Unable to create user, Please try it again! ' + _errorInfo;
            this.showStatusMessage(this.message, 'Create User', 'error');
          }

        },

        error: (err: any) => {
          this.spinnerService.hide();
          let _err: any = {};
          _err = err;
          this.alertType = 'danger';

          const _errorInfo = '';

          this.message = 'Unable to create user, Please try it again! ' + _errorInfo;
          this.showStatusMessage(this.message, 'Create User', 'error');
        },
        complete: () => {
          //complete
        }
      }
      );

  }

  setCurrentRole(selected: string) {

    if (this.roles && Object.keys(this.roles).length > 0) {
      const roleDefault = this.roles.find(c => c.name === selected);

      if (roleDefault)
        this.loginForm.get('role')?.setValue(roleDefault.name);
    }
  }

  loadRoles() {
    this.hasLoadedRoles = false;
    this.roles = {};
    //this.spinnerService.show();
    this.authorizeService.getRoles()
      .subscribe({
        next: (result) => {
          //this.spinnerService.hide();
          let _result: any = {};
          _result = result;

          this.hasLoadedRoles = true;
          if (_result.success) {

            this.roles = _result.result;
            this.setCurrentRole(this.role);
          }

        },

        error: (err: any) => {
          this.hasLoadedRoles = true;
          let _err: any = {};

          _err = err;
          this.alertType = 'danger';

          const _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '' + _err.error.ModelState[''][0] : '';
          // this.message = 'Error while loading roles ' + _errorInfo;
          //this.spinnerService.hide();
        },
        complete: () => { }
      }
      );


  }
  showStatusMessage(message: any, action: any, severity: any = '') {
    severity == '' ? 'success' : 'error';

    this.messageService.add({ severity: severity, summary: action, detail: message });
  }
  validPasswordRule(ruleType, val) {
    switch (ruleType) {
      case 'NUMBER': return this.sharedService.validatePasswordRule(PasswordRuleType.Number, val);
      case 'UPPER': return this.sharedService.validatePasswordRule(PasswordRuleType.UpperCase, val);
      case 'LOWER': return this.sharedService.validatePasswordRule(PasswordRuleType.LowerCase, val);
      case 'SPECIAL': return this.sharedService.validatePasswordRule(PasswordRuleType.SpecialCharacter, val);
      case 'MIN_LENGTH': return val.length >= 8;
    }
  }

  hasValidPasswordAndForm() {
    const val = this.loginForm.get('newPassword').value;
    const valid = (this.sharedService.validatePasswordRule(PasswordRuleType.Number, val) &&
      this.sharedService.validatePasswordRule(PasswordRuleType.UpperCase, val) &&
      this.sharedService.validatePasswordRule(PasswordRuleType.LowerCase, val) &&
      this.sharedService.validatePasswordRule(PasswordRuleType.SpecialCharacter, val)
    );
    if (this.loginForm.invalid)
      return false;
    else
      return valid;

  }


}
export function phoneValidator(c: AbstractControl) {
  const validCharacters = /^[()-\s\d]+$/;
  if (c.value && c.value.length <= 7)
    return { validPhone: true };
  if (c.value && (!validCharacters.test(c.value))) {

    return { validPhone: true };
  }

  return null;
}

export function emailValidator(control: AbstractControl) {

  if (control.value && (!control.value.includes('@') || !control.value.includes('.'))) {
    return { validEmail: true };
  }
  return null;
}

