import { Component, OnInit, ViewChild } from '@angular/core';

import { AgGridAngular } from 'ag-grid-angular';
import { ValueGetterParams } from 'ag-grid-community';

import { ReportService } from '../services/report.service';

@Component({
    selector: 'app-report-conseq-occur',
    templateUrl: './report-conseq-occur.component.html',
    styleUrls: ['./report-conseq-occur.component.css'],
    standalone: false
})
export class ReportConseqOccurComponent implements OnInit {

  error: string;

  resultCons: any = [];
  reportJSON: any = {
    "reportType": "",
    "report": ""
  };

  @ViewChild('grid') agGrid: AgGridAngular;
 
  ColDef;
  columnDefs;
  gridApi;
  autoGroupColumnDef;

  constructor(public reportService: ReportService) {
    this.error = "";

    this.columnDefs = [
      { field: 'occurrence'},
      { field: 'count' },
      {
        field: 'events with at least one fatality',
      },
      { field: 'events with at least one serious injury' },
      { field: 'events with at least one minor injury' },
      { field: 'events with at least one injury of unspecified severity(aids report)' },
      { field: 'aircraft destoryed' },
      { field: 'substantial damage' },
      { field: 'minor damage' },
      {
        headerName: 'Risk Score',
        //field: 'risk score',
        valueGetter: riskValueGetter,
      }

    ];

  }

  ngOnInit(): void {
    this.loadReport('conseq_occur');
  }

  onGridReady(params) {
    try {
      this.gridApi = params.api;
   
    } catch (e) {
      console.error("Ouch" + e);
      this.error = "Error";
    }
  }

  loadReport(report) {
    try {

      this.reportJSON.reportType = report;
      this.reportJSON.report = report;
      this.reportService.getStatisticsReport(this.reportJSON).subscribe({
        next: (res: any) => {

          //res.result.foreach(r => {
          //  console.log(r['events with at least one fatality']);
          //})

          this.resultCons = res.result;
        },
        error: (e) => {
          console.error("Ouch" + e);
        }
      });

    } catch (e) {
      console.error("Ouch");
      this.error = "Error";
    }

  }

}

function riskValueGetter(params: ValueGetterParams) {
  let riskScore = 0;

  try {
    riskScore = (params.data['events with at least one fatality'] * 1) +
      (params.data['events with at least one serious injury'] * 0.5) +
      (params.data['events with at least one minor injury'] * 0.25) +
      (params.data['events with at least one injury of unspecified severity(aids report)'] * 0.25) +
      (params.data['aircraft destoryed'] * 0.5) +
      (params.data['substantial damage'] * 0.01) +
      (params.data['minor damage'] * 0.001);
  } catch (e) {
    console.error("Ouch");
    this.error = "Error";
  }

  return riskScore.toFixed(3);
}

