import { Component, OnInit, ViewChild } from '@angular/core';

import { AgGridAngular } from 'ag-grid-angular';
import {  ChartRef } from 'ag-grid-community';

import { ReportService } from '../services/report.service';


@Component({
    selector: 'app-report-statistics',
    templateUrl: './report-statistics.component.html',
    styleUrls: ['./report-statistics.component.css'],
    standalone: false
})
export class ReportStatisticsComponent implements OnInit {

  error: string;

  result139: any = [];
  resultnon139: any = [];
  resultdetails: any = [];
  resultInjury: any = [];
  reportJSON: any = { "type": "" };

  @ViewChild('agGrid') p139_agGrid: AgGridAngular;
  p139_gridColumnApi;
  p139_ColDef;
  p139_columnDefs;
  p139_gridApi;
  p139_autoGroupColumnDef;

  @ViewChild('agGrid_non139') non139_agGrid: AgGridAngular;
  non139_gridColumnApi;
  non139_ColDef;
  non139_columnDefs;
  non139_gridApi;
  non139_autoGroupColumnDef;

  @ViewChild('agGrid') details_agGrid: AgGridAngular;
  details_gridColumnApi;
  details_ColDef;
  details_columnDefs;
  details_gridApi;
  details_autoGroupColumnDef;
  details_option;

  p139currentChartRef: ChartRef = null;
  /*public p139chartptions: AgCartesianChartOptions;*/

  non139currentChartRef: ChartRef = null;
  //public non139chartptions: AgCartesianChartOptions;

  detailsPvsNChartRef: ChartRef = null;
/*  public detailsPvsNchartptions: AgCartesianChartOptions;*/

  detailsPartsChartRef: ChartRef = null;
/*  public detailspartschartptions: AgCartesianChartOptions;*/

  detailscurrentChartRef: ChartRef = null;
/*  public detailschartptions: AgCartesianChartOptions;*/

  detailsARFChartRef: ChartRef = null;
  //public arfchartptions: AgCartesianChartOptions;

  constructor(public reportService: ReportService) {
    this.error = "";

    this.p139_columnDefs = [
      { field: 'airport_name', width: 220 },
      { field: 'incident_total' }
    ];

    this.non139_columnDefs = [
      { field: 'airport_name', width: 220 },
      { field: 'incident_total' }
    ];

    this.details_columnDefs = [
      { field: 'type'},
      { field: 'value' }
    ];

    //For Airport Related Factors
    //type: 'bar',
    //xKey: 'quarter',
    //yKey: 'iphone',
    //yName: 'iPhone', 

  }

  ngOnInit(): void {
    this.loadReport('p139');
    this.loadReport('non139');
    this.loadReport('detail_damage');
    this.loadReport('detail_injury');
  }

  onp139GridReady(params) {
    try {
      this.p139_gridApi = params.api;
      this.p139_gridColumnApi = params.columnApi;
    } catch (e) {
      console.error("Ouch" + e);
      this.error = "Error";
    }
  }

  onnon139GridReady(params) {
    try {
      this.non139_gridApi = params.api;
      this.non139_gridColumnApi = params.columnApi;
    } catch (e) {
      console.error("Ouch" + e);
      this.error = "Error";
    }
  }

  ondetailsGridReady(params) {
    try {
      this.details_gridApi = params.api;
      this.details_gridColumnApi = params.columnApi;
    } catch (e) {
      console.error("Ouch" + e);
      this.error = "Error";
    }
  }

  onp139ModelUpdated(params) {
    try {
      if (this.p139currentChartRef != null)
        this.p139currentChartRef.destroyChart();
      var createRangeChartParams = {
        cellRange: {
          columns: ['airport_name', 'incident_total'],
        },

        chartType: 'groupedColumn', 
        chartContainer: document.querySelector('#p139Chart'),

        chartThemeOverrides: {
          common: {
            title: {
              enabled: true,
              text: 'Part 139',
              fontWeight: 'bold',
              fontSize: 16
            },
            width: 400,
            padding: {
              top: 20,
              left: 0,
              bottom: 0,
              right: 0,
            },
            legend: {
              enabled: false,
            },
          },
        },
      };

      this.p139currentChartRef = params.api.createRangeChart(createRangeChartParams);
    } catch (e) {
      console.error("Ouch" + e);
      this.error = "Error";
    }
  }

  onnon139ModelUpdated(params) {
    try {
      if (this.non139currentChartRef != null)
        this.non139currentChartRef.destroyChart();
      var createRangeChartParams = {
        cellRange: {
          columns: ['airport_name', 'incident_total'],
        },

        chartType: 'groupedColumn', 
        chartContainer: document.querySelector('#non139Chart'),

        chartThemeOverrides: {
          common: {
            title: {
              enabled: true,
              text: 'Non-Part 139',
              fontWeight: 'bold',
              fontSize: 16
            },
            width: 400,
            padding: {
              top: 20,
              left: 0,
              bottom: 0,
              right: 0,
            },
            legend: {
              enabled: false,
            },
          },
        },

      };

      this.non139currentChartRef = params.api.createRangeChart(createRangeChartParams);
    } catch (e) {
      console.error("Ouch" + e);
      this.error = "Error";
    }
  }

  ondetailsModelUpdated(params) {
    try {
      if (this.detailscurrentChartRef != null)
        this.detailscurrentChartRef.destroyChart();
      if (this.detailsPvsNChartRef != null)
        this.detailsPvsNChartRef.destroyChart();
      if (this.detailsPartsChartRef != null)
        this.detailsPartsChartRef.destroyChart();
      if (this.detailsARFChartRef != null)
        this.detailsARFChartRef.destroyChart();

      //PvsN
      var PvsNChartParams = {
        cellRange: {
          rowStartIndex: 4,
          rowEndIndex: 5,
          columns: ['type', 'value'],
        },

        chartType: 'pie', 
        chartContainer: document.querySelector('#p139vsnonp139Chart'),
        chartThemeOverrides: {
          common: {
            title: {
              enabled: true,
              text: 'Part 139 vs Non-Part 139',
              fontWeight: 'bold',
              fontSize: 16
            },
            width: 300,
            padding: {
              top: 30,
              left: 0,
              bottom: 30,
              right: 0,
            },
            legend: {
              enabled: true,
              position: 'bottom',
            },
          },
        },
      };

      //Parts Chart
      var PartsChartParams = {
        cellRange: {
          rowStartIndex: 6,
          rowEndIndex: 10,
          columns: ['type', 'value'],
        },

        chartType: 'groupedColumn', 
        chartContainer: document.querySelector('#partsChart'),
        chartThemeOverrides: {
          common: {
            title: {
              enabled: true,
              text: 'Parts',
              fontWeight: 'bold',
              fontSize: 16
            },
            width: 300,
            padding: {
              top: 30,
              left: 0,
              bottom: 30,
              right: 0,
            },
            legend: {
              enabled: true,
              position: 'bottom',
            },
          },
        },
      };

      //Damage Chart
      var createRangeChartParams = {
        cellRange: {
          rowStartIndex: 0,
          rowEndIndex: 3,
          columns: ['type', 'value'],
        },

        chartType: 'pie', 
        chartContainer: document.querySelector('#detailsChart'),
        chartThemeOverrides: {
          common: {
            title: {
              enabled: true,
              text: 'Aircaft Damage',
              fontWeight: 'bold',
              fontSize: 16
            },
            width:300,
            padding: {
              top: 30,
              left: 0,
              bottom: 30,
              right: 0,
            },
            legend: {
              enabled: true,
              position: 'bottom',
            },
          },
        },
      };

      //ARFChart
      var ARFChartParams = {
        cellRange: {
          rowStartIndex: 11,
          rowEndIndex: 12,
          columns: ['type', 'value'],
        },

        chartType: 'groupedColumn', 
        chartContainer: document.querySelector('#ARFChart'),
        chartThemeOverrides: {
          common: {
            title: {
              enabled: true,
              text: 'Airport Related Factors',
              fontWeight: 'bold',
              fontSize: 16
            },
            width: 300,
            padding: {
              top: 30,
              left: 0,
              bottom: 30,
              right: 0,
            },
            legend: {
              enabled: true,
              position: 'bottom',
            },
          },
        },
      };


      this.detailsPvsNChartRef = params.api.createRangeChart(PvsNChartParams);
      this.detailsPartsChartRef = params.api.createRangeChart(PartsChartParams);
      this.detailscurrentChartRef = params.api.createRangeChart(createRangeChartParams);
      this.detailsARFChartRef = params.api.createRangeChart(ARFChartParams);
    } catch (e) {
      console.error("Ouch" + e);
      this.error = "Error";
    }
  }

  loadReport(report) {
    try {

      this.reportJSON.type = report;
      this.reportService.getStatisticsReport(this.reportJSON).subscribe({
        next: (res: any) => {
          switch (report) {
            case 'p139':
              this.result139 = res.result;
              break;
            case 'non139':
              this.resultnon139 = res.result;
              break;
            case 'detail_damage':
              this.resultdetails = res.result;
              break;
            case 'detail_injury':
              this.resultInjury = res.result;
              break;
          }
        },
        error: (e) => {
          console.error("Ouch" + e);
        }
      });

    } catch (e) {
      console.error("Ouch" + e);
      this.error = "Error";
    }

  }

}
