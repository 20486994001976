import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

import { ReportService } from '../../../services/report.service';
import { SpinnerService } from '../../../services/spinner.service';

@Component({
    selector: 'app-sidebar-detail-view',
    templateUrl: './detail-view.component.html',
    styleUrls: ['./detail-view.component.css'],
    standalone: false
})
export class DetailViewComponent implements OnInit {
  @Input() showSideBarEditView = false;
  @Input() showSideBarDetailView;
  @Input() hasQueryGridData;
  @Input() rowDataDetailView;
  @Input() selectedRowIndex;
  @Input() columnDefs;
  @Input() gridApiDetail;
  @Input() resultReportColumns;
  @Input() type = '';
  @Input() sqlQuery = '';
  @Input() totalRecords;
  @Input() gridApi;
  @Output() onCloseSideBarDetailView = new EventEmitter();
  selectedRow = {};
  result = {}
  id = '';
  gridColumnApi;
  rowData:  any[] | null;
  isReadOnly = false;
  hasOtherDataSourceType = false;
  first = 0;
  constructor(public reportService: ReportService, private spinnerService: SpinnerService) { }

  ngOnInit(): void {
     if (this.showSideBarDetailView && this.selectedRowIndex > -1) 
    this.onInit();
  }
  onInit() {

    this.rowData = this.rowDataDetailView;
   
    if (this.showSideBarDetailView && this.selectedRowIndex > -1) {
      const rowNode = this.gridApiDetail.getDisplayedRowAtIndex(this.selectedRowIndex);
      this.first = this.selectedRowIndex;
      this.selectedRow = rowNode.data;
      this.getData();
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.showSideBarDetailView && this.selectedRowIndex > -1) 
    this.onInit();
  }
  onDetailViewGridReady(params) {
    this.gridApiDetail = params.api;
  }
  onHide() {
    this.showSideBarEditView = false;
    this.showSideBarDetailView = false;
    this.hasOtherDataSourceType = false;
    this.onCloseSideBarDetailView.emit(this.showSideBarDetailView);
  }
  onPageChange(event: any) {
 
    if (this.rowDataDetailView && this.showSideBarDetailView) {
      this.first = event.first;
      this.selectedRowIndex = (event.first);
      this.setPageData();
    }
  }
  onPaginationChanged(params: any) {
    this.setPageData();
  }
  setPageData() {
    
    if (this.rowDataDetailView && this.showSideBarDetailView) {
      this.hasOtherDataSourceType = false;
      this.isReadOnly = false;
      const rowNode = this.gridApiDetail.getDisplayedRowAtIndex(this.selectedRowIndex);
      this.selectedRow = rowNode.data;
      this.isReadOnly = true;
      this.getData();
    }
  }
  getData() {
    this.hasOtherDataSourceType = this.type == 'NTSB_LINK' || this.type == 'RWS_LINK' || this.type == 'VPDS_LINK' || this.type == 'PDS_LINK' || this.type == 'AIDS_LINK' || this.type == 'ASRS_LINK'
      || this.type == 'NTSB' || this.type == 'RWS' || this.type == 'VPDS' || this.type == 'PDS' || this.type == 'AIDS' || this.type == 'ASRS';

    if (this.hasOtherDataSourceType) {
      this.loadData();
    }
  }
  loadData() {

    if (this.type && this.type.length > 0) {
      this.hasOtherDataSourceType = this.type == 'NTSB_LINK' || this.type == 'RWS_LINK' || this.type == 'VPDS_LINK' || this.type == 'PDS_LINK' || this.type == 'AIDS_LINK' || this.type == 'ASRS_LINK'
        || this.type == 'NTSB' || this.type == 'RWS' || this.type == 'VPDS' || this.type == 'PDS' || this.type == 'AIDS' || this.type == 'ASRS';
      let type = '';
      let sqlQuery = '';
      switch (this.type) {
        case 'NTSB':
          type = 'NTSB_LINK';
          this.id = this.selectedRow ? this.selectedRow['event_id'] : '';
          sqlQuery = `(event_id = '${this.id}' collate binary_ci )`;
          break;
        case 'RWS':
          type = 'RWS_LINK';
          this.id = this.selectedRow ? this.selectedRow['report_#'] : '';
          sqlQuery = `(report_# = '${this.id}' collate binary_ci )`;
          break;

        case 'VPDS':
          type = 'VPDS_LINK';
          this.id = this.selectedRow ? this.selectedRow['report_#'] : '';
          sqlQuery = `(report_# = '${this.id}' )`;
          break;

        case 'PDS':
          type = 'PDS_LINK';
          this.id = this.selectedRow ? this.selectedRow['rprt_nbr'] : '';
          sqlQuery = `(RPRT_NBR = '${this.id}')`;
          break;

        case 'AIDS':
          type = 'AIDS_LINK';
          this.id = this.selectedRow ? this.selectedRow['rprt_nbr'] : '';
          sqlQuery = `(RPRT_NBR = '${this.id}')`;
          break;

        case 'ASRS':
          type = 'ASRS_LINK';
          this.id = this.selectedRow ? this.selectedRow['acn'] : '';
          sqlQuery = `(ACN = '${this.id}')`;
          break;
      }
     
     

      this.rowData = null;
      this.result = {};
      this.spinnerService.show();
      this.reportService.getReportData(type, sqlQuery)
        .subscribe({
          next: (result) => {
            let _result: any = {};
            _result = result;
            this.result = _result;
            this.rowData = _result.result;
            this.spinnerService.hide();
            if (_result.success) {

              setTimeout(() => {
                this.selectedRow = _result.result[0];

              }, 0);
            }

          },

          error: (err: any) => {
            this.result = {};
            this.spinnerService.hide();
            let _err: any = {};
            _err = err;

            let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';

          },
          complete: () => { this.spinnerService.hide(); }
        }
        );
        
    }
  }
  getBsClass(field: string) {
    let defaultBsClass = 3;
    Object.entries(this.resultReportColumns).forEach(([key, val]) => {

      if (val['column_name_json'] == field) {

        defaultBsClass = val['bs_class'];
        if (defaultBsClass && defaultBsClass > 0)
          return 'col-md-' + defaultBsClass;
      }
    });
    return 'col-md-' + defaultBsClass;
  }
}
